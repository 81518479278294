<template>
    <div>
        <v-card class="mb-1" elevation="0">
            <v-card-title>
                <strong class="titulo-header-page">Acompanhar Boletos Gerados</strong>
                <v-spacer></v-spacer>
            </v-card-title>

            <div>
                <v-row dense>
                    <v-col cols="12" md="12">
                        <div class="pl-5 pr-5">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        color="#007744"
                                        label="Nome Titular:"
                                        v-model="nomeTitular"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6" md="3">
                                    <v-text-field
                                        color="#007744"
                                        label="Data Inicial:"
                                        type="date"
                                        v-model="datas.dataIni"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6" md="3">
                                    <v-text-field
                                        color="#007744"
                                        label="Data Final:"
                                        type="date"
                                        v-model="datas.dataFim"
                                        required
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <loading v-if="carregamento" />
        <found v-else-if="!carregamento && data.length === 0" />
        <v-simple-table v-else dense fixed-header height="400px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Titular</th>
                <th class="text-left">Contato</th>
                <th class="text-left">N° Cobrança</th>
                <th class="text-left">Data de Vencimento</th>
                <th class="text-left">Situação</th>
                <th class="text-left">Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in titulares" :key="index">
                <td class="text-left">{{ item.titular }}</td>
                <td class="text-left">{{ item.contato }}</td>
                <td class="text-left">{{ item.n_cobranca }}</td>
                <td class="text-left">{{ item.data_vencimento }}</td>
                <td class="text-left">
                  <v-chip v-if="item.situacao === 'Em Aberto'" color="grey" outlined x-small>
                    {{ item.situacao }}
                  </v-chip>
                  <v-chip v-else color="green" outlined x-small>
                    {{ item.situacao }}
                  </v-chip>
                </td>
                <td class="text-left">
                  <v-btn
                      v-if="item.situacao === 'Em Aberto'"
                      color="#007744"
                      class="white--text"
                      x-small
                      @click="visualizarCobranca(item.visualizar)"
                  >
                      <v-icon class="text--center">mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                      v-else
                      color="#007744"
                      class="white--text"
                      x-small
                      disabled
                  >
                      <v-icon class="text--center">mdi-eye-off</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="totais">
          <ul>
            <li>Quantidade de cobranças: {{ titulares.length }}</li>
            <li>Cobranças em Aberto: {{ emAberto }}</li>
            <li>Cobranças Quitadas: {{ quitados }}</li>
          </ul>
        </div>
    </div>
</template>

<script>
import axios from '../../axios/service_private';

var moment = require("moment");
moment.locale("pt-br");

export default {
    data: () => ({
        data: [],
        titulares: [],
        titular: {
            nome: null,
            link: null,
            contato: null,
            data_criacao: null
        },
        carregamento: false,
        datas: {
            dataIni: moment().startOf("month").format("YYYY-MM-DD"),
            dataFim: moment().format("YYYY-MM-DD")
        },
        quitados: 0,
        emAberto: 0,
        nomeTitular: ''
    }),
    methods: {
        async getTitulares() {
            this.carregamento = true;
            try {

                const titulares = await axios.get(`api/v1/pet-primavera/cobrancas`, {
                  params: { dataIni: this.datas.dataIni, dataFim: this.datas.dataFim }
                });
                
                if (titulares.data.status) {
                    this.carregamento = false;
                    this.data = titulares.data.cobrancas;
                    this.titulares = this.data;
                    this.situacoes(this.titulares);
                }

            } catch(error) {
                this.carregamento = false;
                this.$toast(error.response.data.message, {
                    type: 'error'
                });
            }
        },
        situacoes(array) {
          array.forEach(item => {
              if (item.situacao === 'Quitado') {
                  this.quitados += 1;
              } else if (item.situacao === 'Em Aberto') {
                  this.emAberto += 1;
              }
          });
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        visualizarCobranca(link) {
            if (link === 'Link não encontrado') {
                this.$toast(link, {
                    type: 'error'
                });
            } else {
                window.open(link, '_blank');
            }
        },
        async setup() {
            this.$store.dispatch("verifyToken");
            await this.getTitulares();
        }
    },
    watch: {
      async 'datas.dataIni'() {
        this.quitados = 0;
        this.emAberto = 0;
        await this.getTitulares();
      },
      async 'datas.dataFim'() {
        this.quitados = 0;
        this.emAberto = 0;
        await this.getTitulares();
      },
      'nomeTitular'(novoValor) {
        if (novoValor === '') {
          this.titulares = this.data;
        } else {
          this.titulares = this.data.filter(titular =>
            titular.titular.toLowerCase().includes(novoValor.toLowerCase())
          );
        }
      }
    },
    async mounted() {
        await this.setup();
    }
}
</script>

<style scoped>

.totais {
  padding: 20px 20px;
  background: #eeeeee;
}

.totais ul {
  list-style: none;
}

.totais li {
  font-weight: bold;
  padding-top: 10px;
}

.space {
  margin: 14px 5px;
}
.v-chip-size {
  max-width: 200px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.informacoes {
  margin-bottom: 2rem;
}
fieldset {
  border: 1px solid lightgray;
}
.itemsContainerContact {
  font-size: 0.8rem;
  font-weight: 400;
  width: 45%;
}
.imagem-pet img {
  width: 40px;
  cursor: pointer;
}
.info-parcela {
  margin-top: -20px;
  margin-bottom: 1rem;
}
.carencia {
  background: rgba(248, 248, 117, 0.904);
}
.deletado {
  background: rgba(212, 97, 89, 0.904);
}
.obito {
  background: rgba(211, 157, 7, 0.904);
}
.divide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartao-pet {
  padding: 20px 20px;
}
.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}
.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}
.cartao-pet img {
  width: 100%;
  height: 100%;
}
.imagem-pet-icon-horizontal img {
  width: 20px;
}
.imagem-pet-icon img {
  width: 35px;
}
.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}
.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}
.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}
.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}
.cartao-pet {
  padding: 20px 20px;
}
.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}
.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}
.cartao-pet img {
  width: 100%;
  height: 100%;
}
.imagem-pet-icon-horizontal img {
  width: 20px;
}
.imagem-pet-icon img {
  width: 35px;
}
.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}
.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}
.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}
.texto_cartao_cpf {
  position: absolute;
  margin-top: -45px;
}
.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}
.margin-negativa {
  margin-top: -30px;
  margin-bottom: 30px;
}
.estornarObito {
  background: orange;
}
#extrato {
  padding: 20px 20px;
}
#extrato img {
  width: 160px;
}
#extrato h1 {
  font-size: 18px;
  text-align: right;
}
#extrato .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#extrato ul {
  list-style: none;
}
#extrato ul li {
  text-align: right;
}
.v-list-item__title {
  white-space: pre-line;
}
.primary-text {
  color: #007744;
  list-style: none;
  padding: 10px 10px;
  font-weight: bold;
}
.copiar_codigo_pix {
  padding: 20px 20px;
  background: #ececec;
  text-align: center;
}

.baixado {
  background: #007744;
  color: #ddd;
}

.cancelado {
  background: rgb(209, 67, 67);
  color: #ddd;
}

.total {
  width: 100%;
}

.titulo-header-page-legenda {
    margin-top: 10px;
}

.buttom-steps {
    margin-top: 30px;
    margin-right: 20px;
}

</style>
