import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Permissoes from '../views/Permissoes.vue'
import Usuarios from '../views/Usuarios.vue'
import Grupos from '../views/Grupos.vue'
import Estados from '../views/Estados.vue'
import Cidades from '../views/Cidades.vue'
import Especies from '../views/Especies.vue'
import Racas from '../views/Racas.vue'
import Planos from '../views/Planos.vue'
import TitularesContratos from '../views/TitularesContratos.vue'
import EnvioCobrancas from '../views/EnvioCobrancas.vue';
import TitularContrato from '../views/TitularContrato.vue'
import RelatorioBaixasUsuario from '../views/RelatorioBaixasUsuario.vue'
import RelatorioClientesAtrasos from '../views/RelatorioClientesAtrasos.vue'
import categoriaObservacoes from '../views/CategoriaObservacoes.vue'
import RelatorioNovosContratos from '../views/NovosContratos';
import RelatoriosMensais from '../views/RelatoriosMensais';
import Contratos from '../views/Contratos.vue';


Vue.use(VueRouter)

const auth = async (to, from, next) => {
  let token = window.localStorage.getItem('token_web_pet_primavera');

  if (!token) {
    return next('/entrar');
  }

  next();
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/relatorios-mensais',
    name: 'Mensais',
    component: RelatoriosMensais,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/contratos-assinados',
    name: 'Contratos',
    component: Contratos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/relatorio-novos-contratos',
    name: 'contratos',
    component: RelatorioNovosContratos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/relatorio-baixas-usuarios',
    name: 'Baixas',
    component: RelatorioBaixasUsuario,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/relatorio-clientes-atrasos',
    name: 'atrasos',
    component: RelatorioClientesAtrasos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/categoria-observacoes',
    name: 'Observacos',
    component: categoriaObservacoes,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/titulares-contratos',
    name: 'Titulares',
    component: TitularesContratos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/envio-cobrancas',
    name: 'EnvioCobrancas',
    component: EnvioCobrancas,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/contrato',
    name: 'TitularContrato',
    component: TitularContrato,
    meta: {
      allowAnonymous: true
    },
    beforeEnter: auth
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/planos',
    name: 'Planos',
    component: Planos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/racas-animais',
    name: 'Raças',
    component: Racas,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/especies-animais',
    name: 'Especies',
    component: Especies,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/cidades',
    name: 'cidades',
    component: Cidades,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/grupos-acessos',
    name: 'Grupos',
    component: Grupos,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/estados',
    name: 'Estados',
    component: Estados,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/permissoes',
    name: 'Permissoes',
    component: Permissoes,
    meta: {
      allowAnonymous: false
    },
    beforeEnter: auth
  },
  {
    path: '/entrar',
    name: 'login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
